<script setup>
// const dialog = ref(false)
const route = useRoute()
const nav = ref(null)
const prevScrollpos = ref(0)
const internal = useInternal()
const cart = useCartStore()
const menu = () => {
  internal.useMenu(true)
}

function handleScroll() {
  const currentScrollPos = window.pageYOffset

  nav.value.style.top = currentScrollPos > prevScrollpos.value ? '-96px' : 0
  prevScrollpos.value = currentScrollPos
}

onMounted(() => window.addEventListener('scroll', handleScroll))
onBeforeUnmount(() => window.removeEventListener('scroll', handleScroll))
</script>

<template>
  <div
    ref="nav"
    class="n"
  >
    <vPreNav />
    <div class="nav">
      <nav>
        <div class="cnt">
          <NuxtLink
            :to="{ name: 'index' }"
            aria-label="Naslovna"
          >
            <img
              src="/images/logo.png"
              alt="Stilles"
            >
          </NuxtLink>
        </div>
        <div class="nav_right">
          <!-- <NuxtLink :to="{ name: 'index' }" class="fx mh">
          /
        </NuxtLink> -->
          <template v-if="route.name !== 'pretraga'">
            <NuxtLink
              :to="{ name: 'plocasti-materijali' }"
              class="fx mh"
            >
              PLOČASTI <span>MATERIJALI</span>
            </NuxtLink>
            <NuxtLink
              :to="{ name: 'okov-za-namestaj' }"
              prefetch
              class="fx mh"
            >
              OKOV <span>ZA NAMEŠTAJ</span>
            </NuxtLink>
            <NuxtLink
              :to="{ name: 'namestaj' }"
              class="fx mh"
            >
              NAMEŠTAJ
            </NuxtLink>
            <NuxtLink
              :to="{ name: 'novosti' }"
              class="fx mh"
            >
              NOVOSTI
            </NuxtLink>
            <NuxtLink
              :to="{ name: 'usluge' }"
              class="fx mh"
            >
              USLUGE
            </NuxtLink>

            <!-- <vExternal
            where="https://masinezaobradudrveta.com"
            class="fx mh vam"
          >
            MAŠINE
          </vExternal> -->
            <NuxtLink
              :to="{ name: 'o-nama' }"
              class="fx mh"
            >
              O NAMA
            </NuxtLink>
            <NuxtLink
              :to="{ name: 'kontakt' }"
              class="fx mh"
            >
              KONTAKT
            </NuxtLink>
          </template>

          <NuxtLink
            :to="{ name: 'korpa' }"
            :class="['cart', { has: !cart.loading && cart.quantitySum !== 0 }]"
            aria-label="Korpa"
          >
            <span
              v-if="!cart.miniCart"
              class="fx tar"
            >
              {{ formatCurrency((cart.cart?.totals?.total_price ?? 0) / 100) }}
            </span>
            <vSpinner v-if="cart.loading" />
            <vIcon
              v-else
              :id="!Boolean(cart?.quantitySum) ? 'cart-outline' : 'cart'"
              class="tr"
              :fill="!Boolean(cart?.quantitySum) ? 'rgb(var(--color-rgb) / .5)' : 'var(--brand-color)'"
            />
          </NuxtLink>
          <vIcon
            id="menu"
            class="menu"
            @click="menu"
          />
        </div>
      </nav>
      <vAsideMenu />
    </div>
  </div>
  <!-- <Search :show="dialog" /> -->
</template>

<style scoped>
.n {

  position: sticky;
  top: 0;
  transition: top 0.3s;
  background-color: var(--background-color);
  z-index: 10;

}

.menu {
  cursor: pointer;
  color: rgb(var(--color-rgb) / .5);
}

.tr {
  transform: translateX(5px);
}

.vam {
  display: flex;
  align-items: center;
  line-height: 1;

  & svg {
    margin-left: 4px;
  }
}

.dh {
  display: none;
}

.nav {
  /* outline: 1px dashed; */
  width: 100%;
}

nav {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  /* outline: 1px dashed; */
  max-width: 1488px;
  margin: 0 auto;
  /* pointer-events: none; */
}

.fx {
  /* text-align: center; */
  line-height: 1;
  font-weight: 500;
  color: rgb(var(--color-rgb) / .5);
}

.router-link-active {
  color: var(--brand-color);

  & .fx {
    color: var(--color);
  }

  & small {
    color: var(--color);
  }
}

.title {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: 800;
  font-size: 24px;
}

.count {
  font-weight: 800;
  font-size: 24px;
  font-variant-numeric: tabular-nums;
  color: rgb(var(--color-rgb) / .24);
  padding-right: 4px;
}

.nav_right {
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 13px;
}

.cart {
  display: flex;
  align-items: center;
  gap: 4px;

  & span {
    font-size: 14px;
    /* font-variant-numeric: tabular-nums; */
  }

  &.has {
    & .fx {
      color: var(--color);
    }
  }
}

strong {
  font-weight: 700;
}

img {
  width: 110px;
  height: auto;
  vertical-align: middle;
  /* filter: grayscale(); */
}

.cnt {
  display: flex;
  align-items: center;

  & svg {
    margin-right: 4px;
  }
}

.search {
  padding: 0 8px 0 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 16px;
  background-color: rgb(0 0 0 / .06);
  height: 36px;
  line-height: 1;
  color: #6d6d6d;

  & svg {
    margin-left: 8px;
  }
}

.fr {
  padding-right: 0;
}

.separator {
  padding: 0 4px;
  color: rgb(var(--color-rgb) / .12);
}

@media (hover:hover) {

  .menu:hover,
  .fx:hover {
    color: var(--color);
  }
}

@media (max-width: 1340px) {
  .nav_right {
    gap: 16px;
  }

  .fx small.mh,
  .mh {
    display: none;
  }

  .dh {
    display: flex;
  }

  .search {
    background-color: transparent;
    padding: 0 4px 0 0;

    & span {
      display: none;
    }
  }
}
</style>
