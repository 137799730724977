<script setup>
const props = defineProps({
  where: {
    type: String,
    required: true
  }
})
const modal = ref(false)

const openModal = () => {
  document.body.style.overflowY = 'hidden'
  modal.value = true
}
const closeModal = () => {
  document.body.style.overflowY = 'auto'
  modal.value = false
}
const navigate = () => {
  closeModal()
  window.location.href = props.where
}
</script>

<template>
  <div>
    <a
      :href="where"
      rel="nofollow noopener"
      @click.prevent="openModal"
    >
      <slot />
    </a>
    <transition name="modal">
      <div
        v-if="modal"
        class="modal"
      >
        <div class="modal-content">
          <h3>Napomena</h3>
          <p>Sada napuštate ovu veb stranicu</p>
          <div class="cta">
            <button
              class="secondary"
              @click="closeModal"
            >
              NAZAD
            </button>
            <button @click="navigate">
              NASTAVI
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0 0 0 / .5);
  /* backdrop-filter: blur(5px); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  color: black;
  & h3 {
    font-weight: 800;
    font-size: 24px;
    padding-bottom: 4px;
  }
  & .secondary {
    color: rgb(0 0 0 / .5);
  }
}
.cta {
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
}

button {
  font-weight: 600;
  font-size: 14px;

  &.secondary {
    color: rgb(var(--color-rgb) / .5);
  }

  &+button {
    margin-left: 16px;
  }
}
</style>
